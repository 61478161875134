import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { renderToString } from "react-dom/server";
import axios from "axios";
import moment from "moment";
import pancake from '../image/sidebar-icon/bitmart.png'
import history from '../image/sidebar-icon/history.svg'
import { useEffect, useState, useRef } from "react";
import Loading from "./Loading";
import infoicon from "../image/info.svg";
import greenarrow from "../image/greenarrow.svg";
import {
  useSDK,
  useTokenBalance,
  useContract,
  useAddress,
  useContractRead,
  useContractWrite,
} from "@thirdweb-dev/react";
import { ethers } from "ethers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Deshbord = () => {
  const [approveAmt, setApproveAmt] = useState("");
  const [BuyTokenLoading, setBuyTokenLoading] = useState(false);
  const [WithdrawTokensloading, setWithdrawTokensLoading] = useState(false);
  const [ApproveTokensloading, setApproveTokensLoading] = useState(false);
  const [ReferralValue, setRefarralValue] = useState("0x1F1330b5c141Fe612aa8B64da9DCFCec885D60f5");
  const [tokenPriceLive, setTokenPriceLive] = useState(null);
  const [amountValue, setAmountValue] = useState("");
  const [durationValue, setDurationValue] = useState("");


  const isValidUSDTamount = Number(amountValue) >= 100 || amountValue == "";

  const stakeContract = "0xD7F7d8F00F4f63914Ad0ce69DFBc38F7c0422c3F";
  const mainContract = "0xA99600043E84181A9d4137aD1cefB8cfE9138674";
  
  //read functions
  const address = useAddress();
  const { contract } = useContract(
    stakeContract
  );
  const { contract: USDTContract } = useContract(
    mainContract
  );

  const { data: cunWalletBal, isLoading: isCunWalletBalLoading } =
    useTokenBalance(USDTContract,
      stakeContract);

  const { data: walletBal, isLoading: walletBalLoading } = useTokenBalance(
    USDTContract,
    address
  );



  const { data: totalReferralRewards, isLoading: isReferralRewardsLoading } =
    useContractRead(contract, "totalReferralRewards", [address]);

  const { data: userTotalWithdraws, isLoading: isuserTotalWithdrawsLoading } =
    useContractRead(contract, "userTotalWithdraw", [address]);

  // console.log("userTotalWithdraws",userTotalWithdraws);
  const { data: userStakingCount, isLoading: isUserStakingLoading } =
    useContractRead(contract, "userStakingCount", [address]);

  const { data: totalRewards, isLoading: isTotalRewardsLoading } =
    useContractRead(contract, "usertotalReward", [address]);

  const { data: totalInvested, isLoading: istotalInvestedLoading } =
    useContractRead(contract, "totalInvestedAmount", [address]);

  const { data: reward, isLoading: isrewardLoading } = useContractRead(
    contract,
    "totalRewardsReceived",
    [address]
  );

  const { data: directChild, isLoading: isDirectChildLoading } =
    useContractRead(contract, "showAllDirectChild", [address]);

  const { data: indirectChild, isLoading: isIndirectChildLoading } =
    useContractRead(contract, "showAllInDirectChild", [address]);

  const { data: parent, isLoading: isParentLoading } = useContractRead(
    contract,
    "parent",
    [address]
  );

  //approve tokens
  const { mutateAsync: approve, isLoading: isApproveLoading } =
    useContractWrite(USDTContract, "approve");
  const approveTokens = async () => {
    try {
      setApproveTokensLoading(true);
      let spender = stakeContract; //contract address
      let approveAmount = approveAmt * 1000000;

      const data = await approve({ args: [spender, approveAmount], });
      console.info("contract call successs", data);
      setApproveTokensLoading(false);

      toast.success("Successfully approved tokens!", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message_custom_success",
      });
    } catch (err) {
      setApproveTokensLoading(false);
      toast.error("Approve Failed !", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message_custom_error",
      });
      console.error("contract call failure", err);
    } finally {
      setApproveAmt("");
      setApproveTokensLoading(false);
    }
  };

  useEffect(() => {
    if (!walletBalLoading && !isCunWalletBalLoading) {
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://cmc-api-tpl8.onrender.com/fxst");
        const tokenPrice = response.data.data.data.FXST.quote.USD.price;
        setTokenPriceLive(tokenPrice.toFixed(4));
      } catch (error) {
        console.error("Error fetching token data:", error);
      }
    };

    fetchData();
  }, []);

  const handleAmountChange = (event) => {
    setAmountValue(event.target.value);
  };

  const handleReferralChange = (event) => {
    setRefarralValue(event.target.value);
  };

  const handleDurationChange = (event) => {
    setDurationValue(event.target.value);
  };

  const ReferralValues = "0x8087f6b3296DbAACCD5C2bBd251C08227545059d"

  // buyTokens
  const { mutateAsync: stakeTokens, isLoading: isBuyTokensLoading } =
    useContractWrite(contract, "stakeTokens");
  let ref;
  if (parent === "0x0000000000000000000000000000000000000000") {
    ref = ReferralValues;
  } else {
    ref = parent;
  }
  const { data: userValid, isLoading: isUserValidLoading } = useContractRead(
    contract,
    "userValidation",
    [ref]
  );

  const [copied, setCopied] = useState(false);
  const addressss = "0x1F1330b5c141Fe612aa8B64da9DCFCec885D60f5";

  const handleCopy = () => {
    navigator.clipboard.writeText(addressss)
      .then(() => {
        setCopied(true);
        toast.success("Address is copied")
      })
      .catch(err => console.error('Could not copy text: ', err));
  };

  //stake Token
  const stakeToken = async (event) => {
    event.preventDefault()
    if (Number(walletBal.displayValue) < 99) {
      toast.error("Insufficient Balance. Minimum balance required is 100 FXST.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message_custom_error",
      });
      return;
    }
    // console.log(ref);
    if (ref || userValid === true) {
      try {
        setBuyTokenLoading(true);
        let usdtAmt = amountValue * 1000000;
        // console.log(ref)
        const data = await stakeTokens({
          args: [usdtAmt, durationValue, ref],
        });
        console.log("contract call successs", data);
        await fetch("https://backend.fxststake.com/v1/plan-buy", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_wallet: address.toLowerCase(),
            parent_wallet_id: ReferralValue.toLowerCase(),
            buyed_plan: [{ amount: usdtAmt }],
            user_id: address.toLowerCase(),
          }),
        });
        setBuyTokenLoading(false);
        toast.success("Tokens Bought Successfully", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message_custom_success",
        });
      } catch (err) {
        setBuyTokenLoading(false);
        toast.error("Something Went Wrong", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message_custom_error",
        });
      }
    } else {
      toast.error("Please Enter a Valid Referral Address", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message_custom_error",
      });
    }
  };

  const handleApproveTokensValue = (event) => {
    setApproveAmt(event.target.value);
  };

  //Stats functions
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const sdk = useSDK();

  const { data: StakingCount, isLoading: isUserStakingCountLoading } =
    useContractRead(contract, "userStakingCount", [address]);

  const [withdrawData, setWithDrawData] = useState("");
  const [tableDataLoading, setTableDataLoading] = useState(true);

  const getData = async (WithdrawCheck) => {
    try {
      WithdrawCheck();
      setTableDataLoading(true);
      setLoading(true);
      const contract1 = await sdk.getContract(
        stakeContract
      );
      let len = Number(StakingCount.toString());
      let details = [];
      for (let i = 0; i < len; i++) {
        const data = await contract1.call("userStaking", [address, i]);
        let amount = (data.stakedAmount.toString() / 1000000).toFixed(2);
        let duration = data.stakingDuration.toString();
        let startDate = moment
          .unix(data.startDate.toString())
          .format("DD-MM-YYYY HH:mm:ss");
        let endDate = moment
          .unix(data.nextClaimTime.toString())
          .format("DD-MM-YYYY HH:mm:ss");

        let Data = [amount, duration, startDate, endDate];
        details.push(Data);
        // console.log ( Data, "daataaaaa")
      }
      setTableDataLoading(false);
      setData(details);

      //console.log("Stake Count Data", details);
    } catch (error) {
      setTableDataLoading(false);
      //console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const WithdrawCheck = async () => {
    let len = Number(StakingCount.toString());
    let promises = [];
    for (let i = 0; i < len; i++) {
      promises.push(
        sdk
          .getContract(stakeContract)
          .then((contract1) =>
            contract1.call("withdrawalCompleted", [address, i])
          )
      );
    }
    // Wait for all promises to resolve
    const result = await Promise.all(promises);
    // Set the accumulated data after all promises are resolved
    setWithDrawData(result);
  };

  useEffect(() => {
    if (!isUserStakingCountLoading) {
      getData(WithdrawCheck);
    }
  }, [isUserStakingCountLoading, address]);

  const { mutateAsync: withdraw, isLoading: isWithdrawTokensLoading } =
    useContractWrite(contract, "withdraw");

  const withdrawToken = async (index) => {
    setWithdrawTokensLoading(true);

    if (Number(cunWalletBal.displayValue) < 0) {
      toast.error("Need to contract top up", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message_custom_error",
      });
      return;
    }

    try {
      const data = await withdraw({ args: [index] });
      console.info("contract call successs", data);
      setWithdrawTokensLoading(false);
      toast.success("Tokens Has Been Successfully Withdrawn", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (err) {
      setWithdrawTokensLoading(false);

      toast.error("Tokens Withdraw Failed", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("contract call failure", err);
    }
  };



  const [currentDateTime, setCurrentDateTime] = useState("");

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };


  useEffect(() => {
    const now = new Date();
    const formattedDateTime = formatDate(now);
    setCurrentDateTime(formattedDateTime);
  }, []);


  function parseCustomDate(dateString) {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('-');
    const [hours, minutes, seconds] = timePart.split(':');

    // Note: Months are 0-indexed in JavaScript, so we subtract 1 from the month
    return new Date(year, month - 1, day, hours, minutes, seconds);
  }


  return (
    <>
      {BuyTokenLoading && <Loading />}
      {ApproveTokensloading && <Loading />}
      <div>
        <div className="content">
          <ToastContainer />
          <div className="cunholder container">
            <div className="container pl-0">
              <h2>
                Hello, <span style={{ color: "#f1c40f" }}>FXST</span> holder !! <blink id="usingCSSBlink" style={{ color: "#f1c40f", }}>( Round 3 Start )</blink>
              </h2>
              {/* <p>Dashboard overview</p> */}
              {/* <h6>( Round 2 Start ) </h6> */}
            </div>
            <h6 style={{ marginTop: '20px' }}>Note : Round 2 of staking has concluded, and now Round 3 begins! <br /> If you participated in the previous staking round, please click on <a style={{ color: '#f3d002' }} href="/purchase">'Round 2'</a> for withdraw.
              <br /> <span style={{ color: '#f3d002' }} >(Stake amount must be a multiple of 10)</span></h6>

            <h6 style={{ marginTop: '20px' }}>Note : <span style={{ color: '#f3d002' }} >Referral addresse</span> cannot be edited after staking tokens. </h6>
          </div>



          <div className="section3">
            <div className="container">
              <div className="section3_chaid">
                {data.map((item, index) => (
                  <div className="balance_info upper_three_card">
                    <p>
                      <p className="indexing">{index + 1}</p>

                    </p>
                    <p>Amount: {item[0]} </p>
                    <p>Duration: {item[1]} Days</p>
                    <p>Returns: {item[1] == 180 ? "50%" : item[1] == 365 ? "90%" : item[1] == 730 ? "250%" : ""}</p>
                    <p>Start Date: {item[2]}</p>
                    <p>Claim Date: {item[3]}</p>
                    {new Date() > parseCustomDate(item[3]) ? (
                      <button
                        onClick={() => withdrawToken(index)}
                        className="button_withdrow"
                      >
                        {" "}
                        Withdraw
                      </button>
                    ) : (
                      <button
                        disabled
                        onClick={() => withdrawToken(index)}
                        className="button_withdrow"
                      >
                        {" "}
                        Locked
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="section1">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 mb-3  pr-md-0">
                  <div className="row mr-0 pl-0 ml-0">
                    <div className="col-lg-12 mb-3 pl-0 pr-0 pr-md-0">
                      <div className="refarinfo3 refarinfo4 first-card second_top_card lower_two_card">
                        <h4>
                          <span>
                            <img src={infoicon} alt="puricon" />
                          </span>
                          Token Price
                        </h4>

                        <h3 className="approve_desc lower_card_price">
                          ${tokenPriceLive ? tokenPriceLive : "0.00"}
                        </h3>
                        <a target="_blank" href="https://www.bitmart.com/trade/en-US?symbol=FXST_USDT">
                          <button

                            // onClick={() => withdrawToken(index)}
                            className="Pancakeswap_button"
                          >
                            <img src={pancake} />
                            Buy On Bitmart
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3 pl-0 pr-0 pr-md-0">
                      <div className="refarinfo3 refarinfo4 first-card second_top_card lower_two_card">
                        <h4>
                          <span>
                            <img src={infoicon} alt="puricon" />
                          </span>
                          Stake & History
                        </h4>

                        <h3 className="approve_desc lower_card_price">

                          {totalInvested ? (
                            <>
                              {!istotalInvestedLoading ? (
                                <div>{totalInvested / 1000000} FXST</div>
                              ) : (
                                "0.00"
                              )}
                            </>
                          ) : (
                            "0.00"
                          )}
                        </h3>
                        <a href="/purchase">
                          <button

                            // onClick={() => withdrawToken(index)}
                            className="Pancakeswap_button"
                          >
                            <img src={history} />
                            Stake History
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-3  pr-md-0">
                  <div className="refarinfo3 refarinfo4 first-card">
                    <h4>
                      <span>
                        <img src={infoicon} alt="puricon" />
                      </span>
                      Stake FXST
                    </h4>

                    {isValidUSDTamount ? null : (
                      <p className="price-warning-text text-danger text-xs">
                        Minimum 100
                      </p>
                    )}
                    <form onSubmit={stakeToken}>
                      <div className="purch desktop_button_share">
                        <input
                          value={amountValue}
                          onChange={handleAmountChange}
                          type="number"
                          placeholder="Enter amount in FXST"
                        />
                      </div>
                      <div className="duration_main_box">
                        <div className="purch desktop_button_share">
                          <select
                            value={durationValue}
                            onChange={handleDurationChange}
                            placeholder="Enter amount in FXST"
                          >
                            <option>Select Duration</option>
                            <option>180</option>
                            <option>365</option>
                            <option>730</option>
                          </select>
                        </div>
                        <div className="purch desktop_button_share per_monthly">
                          {durationValue == "180" ? "50% Total" : durationValue == "365" ? "90% Total" : durationValue == "730" ? "250% Total" : "Returns"}
                        </div>
                      </div>
                      <div className="purch desktop_button_share">
                        <input
                          // required
                          value={
                            parent !==
                              "0x0000000000000000000000000000000000000000"
                              ? parent
                              : ReferralValue
                          }
                          onChange={handleReferralChange}
                          type="text"
                          placeholder="Enter Referral"
                        />
                      </div>

                      <button
                        type="submit"
                        disabled={amountValue < 100}
                        className="purch stake-FXST-button"
                      >
                        Stake
                      </button>
                    </form>


                  </div>
                  <div className="default_address2">
                    <h3 className="approve_desc approve_desc_right">
                      Note : If you don't have any referral address then use this address
                    </h3>
                    <button onClick={handleCopy} style={{
                      background: 'none',
                      border: 'none',
                      padding: 0,
                      color: '#f3d002',
                      cursor: 'pointer',
                      fontSize: '3vw'
                    }}>
                      {addressss}
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 mb-3 mr-0 ml-0  pr-md-4">
                  <div className="refarinfo3 refarinfo4 first-card second_top_card approve_token_card">
                    <h4>
                      <span>
                        <img src={infoicon} alt="puricon" />
                      </span>
                      Approve Tokens
                    </h4>
                    <div className="row pr-2">
                      <div className="col-lg-8 pr-0">
                        <div className="purch desktop_button_share">
                          <input
                            value={approveAmt}
                            onChange={handleApproveTokensValue}
                            type="number"
                            placeholder="Enter amount in FXST"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 pl-0">
                        <button
                          onClick={approveTokens}
                          className="purch stake-FXST-button approve_button_right"
                        >
                          Approve
                        </button>
                      </div>
                    </div>
                    <h3 className="approve_desc approve_desc_right">
                      Approve FXST to interact with FXST <br /> Staking smart contract
                    </h3>
                  </div>
                  <br />
                  <br />
                  <div className="default_address1">
                    <h3 className="approve_desc approve_desc_right">
                      Note : If you don't have any referral address then use this address
                    </h3>
                    <button onClick={handleCopy} style={{
                      background: 'none',
                      border: 'none',
                      padding: 0,
                      color: '#f3d002',
                      cursor: 'pointer',
                      fontSize: '1vw'

                    }}>
                      {addressss}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section3">
            <div className="container">
              <div className="section3_chaid">
                <div className="balance_info">
                  <p>
                    Total Staked
                    <span className="greenarrow">
                      <img src={greenarrow} alt="puricon" />
                    </span>
                  </p>
                  <h4>
                    {" "}
                    {totalInvested ? (
                      <>
                        {!istotalInvestedLoading ? (
                          <div>{totalInvested / 1000000} FXST</div>
                        ) : (
                          "0.00"
                        )}
                      </>
                    ) : (
                      <h5>0.00</h5>
                    )}
                  </h4>
                </div>
                <div className="balance_info">
                  <p>
                    Token Live Price
                    <span className="greenarrow">
                      <img src={greenarrow} alt="puricon" />
                    </span>
                  </p>
                  <h5>${tokenPriceLive ? tokenPriceLive : "0.00"}</h5>
                </div>
                <div className="balance_info">
                  <p>
                    Total Referral Rewards
                    <span className="greenarrow">
                      <img src={greenarrow} alt="puricon" />
                    </span>
                    <h5>
                      {" "}
                      {!isReferralRewardsLoading && totalReferralRewards
                        ? (totalReferralRewards / 1000000)
                        : "0.00"}{" "}
                      FXST
                    </h5>
                  </p>
                </div>

                <div className="balance_info">
                  <p>
                    Total Staking Rewards{" "}
                    <span className="greenarrow">
                      <img src={greenarrow} alt="puricon" />
                    </span>
                  </p>
                  <h4>
                    {" "}
                    {reward && (
                      <>
                        {!isrewardLoading
                          ? (totalRewards / 1000000)
                          : "0.00"}{" "}
                      </>
                    )}
                    FXST
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="section3">
            <div className="container">
              <div className="section3_chaid">
                <div className="balance_info">
                  <p>
                    Total Plan Staked
                    <span className="greenarrow">
                      <img src={greenarrow} alt="puricon" />
                    </span>
                  </p>
                  <h4>
                    {!isUserStakingLoading ? (
                      <div>{parseInt(userStakingCount, 16)} </div>
                    ) : (
                      "0.00"
                    )}
                  </h4>
                </div>
                <div className="balance_info">
                  <p>
                    Direct team / Total team{" "}
                    <span className="greenarrow">
                      <img src={greenarrow} alt="puricon" />
                    </span>
                  </p>
                  <div className="withbalench">
                    {directChild ? (
                      <>
                        <h4>
                          {!isDirectChildLoading ? directChild.length : 0}
                        </h4>
                        <span>/</span>
                        <h4>
                          {!isIndirectChildLoading && !isDirectChildLoading
                            ? indirectChild.length + directChild.length
                            : 0}
                        </h4>
                      </>
                    ) : (
                      <h4>0.00</h4>
                    )}
                  </div>
                </div>
                <div className="balance_info">
                  <p>
                    Total Rewards Received
                    <span className="greenarrow">
                      <img src={greenarrow} alt="puricon" />
                    </span>
                    <h4>
                      {" "}
                      {totalReferralRewards && (
                        <>
                          {(parseFloat(totalRewards) + parseFloat(totalReferralRewards)) / 10 ** 6}
                        </>
                      )}
                      FXST
                    </h4>
                  </p>
                </div>

                <div className="balance_info ">
                  <p>
                    Total Withdraw{" "}
                    <span className="greenarrow">
                      <img src={greenarrow} alt="puricon" />
                    </span>
                  </p>
                  <h4>
                    {userTotalWithdraws ? userTotalWithdraws / 1000000 : 0.0} FXST
                  </h4>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="how-section1 container">
            <h2 style={{ textAlign: 'center', color: '#f1c40f', marginTop: '20px' }}>How to Stake</h2>
            <h6 className="para1"> <span id="usingCSSBlinks">Note : </span> Before clicking the <span style={{ color: '#f1c40f' }}>"Stake" </span> button, please enter the referral address to enjoy referral benefits.</h6> */}

          {/* <ul className="steps">
              <li>Connect Your Wallet</li>
              <li>Approve FXST Tokens</li>
              <li>Enter Amount & Duration</li>
              <li>Click On Stake</li>
            </ul> */}

          {/* <section class="pt-4">
              <div class="container px-lg-5">
                <div class="row gx-lg-5">
                  <div class="col-lg-6 col-xxl-4 mb-5">
                    <div class="card bgg-Img border-0 h-100 p-2">
                      <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <div class="feature back-G text-white rounded-3 mb-4 mt-n4"><i class="bi bi-wallet"></i></div>
                        <h2 class="fs-4 fw-bold">Step 1</h2>
                        <p class="mb-0">Connect Your Wallet</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xxl-4 mb-5">
                    <div class="card bgg-Img border-0 h-100 p-2">
                      <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <div class="feature back-G text-white rounded-3 mb-4 mt-n4"><i class="bi bi-arrow-right-square"></i></div>
                        <h2 class="fs-4 fw-bold">Step 2</h2>
                        <p class="mb-0">Approve FXST Tokens</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xxl-4 mb-5">
                    <div class="card bgg-Img border-0 h-100 p-2">
                      <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <div class="feature back-G text-white rounded-3 mb-4 mt-n4"><i class="bi bi-cash"></i></div>
                        <h2 class="fs-4 fw-bold">Step 3</h2>
                        <p class="mb-0">Enter Amount & Duration</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xxl-4 mb-5">
                    <div class="card bgg-Img border-0 h-100 p-2">
                      <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <div class="feature back-G text-white rounded-3 mb-4 mt-n4"><i class="bi bi-mouse3"></i></div>
                        <h2 class="fs-4 fw-bold">Step 4</h2>
                        <p class="mb-0">Click On Stake</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

          {/* </div> */}

          {/* <h6 className="Note-text">Note : Before clicking the <span style={{ color: '#f1c40f' }}>"Stake" </span> button, please enter the referral address to enjoy referral benefits.</h6> */}

          {/* <div className="video_section">
            <div className="youtube_video">
              <iframe
                width="100%"
                height="500"
                src={link.replace('watch?v=', 'embed/')}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Deshbord;
